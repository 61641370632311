// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-osprzet-cisnieniowy-tsx": () => import("./../../../src/pages/osprzet_cisnieniowy.tsx" /* webpackChunkName: "component---src-pages-osprzet-cisnieniowy-tsx" */),
  "component---src-pages-rurociagi-tsx": () => import("./../../../src/pages/rurociagi.tsx" /* webpackChunkName: "component---src-pages-rurociagi-tsx" */),
  "component---src-pages-urzadzenie-zabezpieczajace-tsx": () => import("./../../../src/pages/urzadzenie_zabezpieczajace.tsx" /* webpackChunkName: "component---src-pages-urzadzenie-zabezpieczajace-tsx" */),
  "component---src-pages-zbiorniki-tsx": () => import("./../../../src/pages/zbiorniki.tsx" /* webpackChunkName: "component---src-pages-zbiorniki-tsx" */)
}

